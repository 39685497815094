import { BASE_PATH } from '@/constants/routes';
import { GlobalContext } from '@/lib/contexts/GlobalContext';
import { TranslationContext } from '@/lib/contexts/TranslationContext';
import { CopyrightOutlined } from '@ant-design/icons';
import { Flex, Layout } from 'antd';
import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
const { Footer } = Layout;

const AppFooter: React.FC = () => {
    const { isHideFooter, merchantPortalOptionSetting } = useContext(GlobalContext);
    const { translate } = useContext(TranslationContext);
    const navigate = useNavigate();
    const buildTime = process.env.BUILD_TIME;

    if (isHideFooter) {
        return null;
    }

    return (
        <Footer style={{
            backgroundColor: 'white',
            textAlign: 'center',
            position: 'relative'
        }}>
            <div>
                <CopyrightOutlined /> 2024 {merchantPortalOptionSetting?.merchantName ? merchantPortalOptionSetting?.merchantName : 'Domigo'}. Developed by Domigo Co.
            </div>
            <div style={{ marginTop: '8px' }}>
                <a onClick={() => navigate(`${BASE_PATH}/terms`)} style={{ marginRight: '16px', cursor: 'pointer' }}>
                    {translate('Terms of service')}
                </a>
                |
                <a onClick={() => navigate(`${BASE_PATH}/privacyPolicy`)} style={{ marginLeft: '16px', cursor: 'pointer' }}>
                    {translate('Privacy policy')}
                </a>
            </div>
            <Flex
                justify="center"
                align="center"
                style={{ margin: '10px' }}>
                <div style={{ fontSize: '10px', color: 'gray' }}>
                    {buildTime}
                </div>
            </Flex>
        </Footer >
    );
};

export default AppFooter;
